import React, { useState, useMemo } from 'react'
import { Input } from "./ui/input"
import { Button } from "./ui/button"
import { ScrollArea } from "./ui/scroll-area"
import { Collapsible, CollapsibleTrigger, CollapsibleContent } from "./ui/collapsible"
import { ChevronDown, Search, Layout, FileText, Sliders, Plus, Clock, MessageSquare } from 'lucide-react'
import { ElgatoLogo } from './ElgatoLogo'
import { categories } from '../data/categories'
import { CategoryItem, Category } from '../types/types'
import { FeedbackModal } from './FeedbackModal'
import { Progress } from "./ui/progress"
import { Separator } from "./ui/separator"

interface SidebarProps {
  onDragStart: (e: React.DragEvent<HTMLLIElement>, item: any, category: string) => void
  setTemplatesOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCurrentView: React.Dispatch<React.SetStateAction<'dashboard' | 'workflow'>>
  currentView: 'dashboard' | 'workflow'
  estimatedTimeSaved: number
}

export function Sidebar({ onDragStart, setTemplatesOpen, setCurrentView, currentView, estimatedTimeSaved }: SidebarProps) {
  const [openCategories, setOpenCategories] = useState<string[]>([])
  const [openSubcategories, setOpenSubcategories] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState('')

  const toggleCategory = (categoryName: string) => {
    setOpenCategories(prev =>
      prev.includes(categoryName)
        ? prev.filter(name => name !== categoryName)
        : [...prev, categoryName]
    )
  }

  const toggleSubcategory = (subcategoryName: string) => {
    setOpenSubcategories(prev =>
      prev.includes(subcategoryName)
        ? prev.filter(name => name !== subcategoryName)
        : [...prev, subcategoryName]
    )
  }

  const filteredCategories = useMemo(() => {
    return categories.map(category => ({
      ...category,
      subcategories: category.subcategories?.map(subcat => ({
        ...subcat,
        items: subcat.items.filter(item =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          item.pluginName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      })).filter(subcat => subcat.items.length > 0),
      items: category.items?.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.pluginName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    })).filter(category => 
      (category.subcategories && category.subcategories.length > 0) ||
      (category.items && category.items.length > 0) ||
      category.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [searchTerm])

  const timeSavedPercentage = Math.min((estimatedTimeSaved / (40 * 60)) * 100, 100)

  return (
    <div className="w-64 bg-background border-r border-border flex flex-col h-screen fixed left-0 top-0">
      <div className="p-4 flex items-center space-x-3 border-b border-border h-[65px]">
        <ElgatoLogo />
        <h1 className="text-xl font-bold leading-none tracking-wide uppercase text-primary">
          Automate
        </h1>
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="p-4 border-b border-border">
          <div className="space-y-1">
            <Button variant="ghost" className="w-full justify-start" onClick={() => setCurrentView('dashboard')}>
              <Layout className="mr-2 h-4 w-4" />
              Dashboard
            </Button>
            <Button variant="ghost" className="w-full justify-start" onClick={() => setTemplatesOpen(true)}>
              <FileText className="mr-2 h-4 w-4" />
              Templates
            </Button>
            <FeedbackModal />
          </div>
        </div>
        {currentView === 'workflow' && (
          <>
            <div className="p-4 border-b border-border">
              <h2 className="text-sm font-semibold mb-2 flex items-center">
                <Sliders className="mr-2 h-4 w-4" />
                Actions
              </h2>
              <div className="relative">
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                <Input
                  type="search"
                  placeholder="Search actions..."
                  className="w-full bg-background text-foreground pl-8"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <ScrollArea className="flex-1">
              <div className="space-y-2 p-2">
                {filteredCategories.map((category) => (
                  <Collapsible
                    key={category.name}
                    open={openCategories.includes(category.name) || searchTerm !== ''}
                    onOpenChange={() => toggleCategory(category.name)}
                  >
                    <CollapsibleTrigger className="flex items-center justify-between w-full px-4 py-2 text-sm font-semibold hover:bg-accent">
                      <span className="flex items-center">
                        {React.createElement(category.icon, { className: "h-4 w-4 mr-2" })}
                        {category.name}
                      </span>
                      <ChevronDown className={`h-4 w-4 transition-transform ${openCategories.includes(category.name) || searchTerm !== '' ? 'transform rotate-180' : ''}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      {category.subcategories ? (
                        category.subcategories.map((subcat) => (
                          <Collapsible
                            key={subcat.name}
                            open={openSubcategories.includes(subcat.name) || searchTerm !== ''}
                            onOpenChange={() => toggleSubcategory(subcat.name)}
                          >
                            <CollapsibleTrigger className="flex items-center justify-between w-full px-6 py-2 text-sm hover:bg-accent">
                              <span>{subcat.name}</span>
                              <ChevronDown className={`h-4 w-4 transition-transform ${openSubcategories.includes(subcat.name) || searchTerm !== '' ? 'transform rotate-180' : ''}`} />
                            </CollapsibleTrigger>
                            <CollapsibleContent>
                              <ul className="mt-1">
                                {subcat.items.map((item) => (
                                  <li
                                    key={item.name}
                                    className="px-8 py-2 hover:bg-accent cursor-move text-sm"
                                    draggable
                                    onDragStart={(e) => onDragStart(e, item, category.name)}
                                  >
                                    {item.name}
                                  </li>
                                ))}
                              </ul>
                            </CollapsibleContent>
                          </Collapsible>
                        ))
                      ) : (
                        <ul className="mt-1">
                          {category.items?.map((item) => (
                            <li
                              key={item.name}
                              className="px-6 py-2 hover:bg-accent cursor-move text-sm"
                              draggable
                              onDragStart={(e) => onDragStart(e, item, category.name)}
                            >
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </CollapsibleContent>
                  </Collapsible>
                ))}
              </div>
            </ScrollArea>
          </>
        )}
      </div>
      <div className="mt-auto p-4 border-t border-border">
        <div className="mb-4">
          <h3 className="text-sm font-semibold mb-2 flex items-center">
            <Clock className="mr-2 h-4 w-4" />
            Time Saved
          </h3>
          <div className="text-2xl font-bold mb-2">{estimatedTimeSaved.toLocaleString()} min</div>
          <div className="h-2 bg-secondary rounded-full overflow-hidden">
            <div
              className="h-full bg-[#00A3E1]"
              style={{ width: `${timeSavedPercentage}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}