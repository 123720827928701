import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Button } from "./ui/button"
import { User, Settings, CreditCard, HelpCircle, LogOut } from 'lucide-react'
import { Avatar } from "./ui/avatar"

export function ProfileMenu() {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button as={Button} variant="ghost" className="p-0">
          <Avatar
            src="https://github.com/shadcn.png"
            alt="Profile"
            fallback="CN"
            className="w-9 h-9"
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-border rounded-md bg-background shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${
                    active ? 'bg-accent text-accent-foreground' : 'text-foreground'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <User className="mr-2 h-5 w-5" aria-hidden="true" />
                  Profile
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${
                    active ? 'bg-accent text-accent-foreground' : 'text-foreground'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <Settings className="mr-2 h-5 w-5" aria-hidden="true" />
                  Stream Deck Settings
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${
                    active ? 'bg-accent text-accent-foreground' : 'text-foreground'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <CreditCard className="mr-2 h-5 w-5" aria-hidden="true" />
                  Subscription
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${
                    active ? 'bg-accent text-accent-foreground' : 'text-foreground'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <HelpCircle className="mr-2 h-5 w-5" aria-hidden="true" />
                  Help & Support
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="px-1 py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={`${
                    active ? 'bg-accent text-accent-foreground' : 'text-foreground'
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  <LogOut className="mr-2 h-5 w-5" aria-hidden="true" />
                  Log out
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}