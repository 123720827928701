import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Button } from './ui/button';
import { ScrollArea } from './ui/scroll-area';
import { Settings, GitBranch, Clock, Webhook, Bell, FileInput, Terminal, RepeatIcon, FileText, Save, Puzzle, GitFork, Zap } from 'lucide-react';
import { LucideIcon } from 'lucide-react';

export interface ElgatoTool {
  id: string;
  name: string;
  description: string;
  icon: LucideIcon;
}

const elgatoTools: ElgatoTool[] = [
  { id: '1', name: 'System Action', description: 'Open apps, manage files, adjust settings', icon: Settings },
  { id: '2', name: 'Conditional Action', description: 'Add If-Then logic to your workflow', icon: GitBranch },
  { id: '3', name: 'Trigger', description: 'Set up event-based triggers', icon: Zap },
  { id: '4', name: 'Delay/Wait', description: 'Pause workflow for a specified time', icon: Clock },
  { id: '5', name: 'Webhook/API Call', description: 'Integrate custom webhooks or API calls', icon: Webhook },
  { id: '6', name: 'Notification', description: 'Create custom alerts and notifications', icon: Bell },
  { id: '7', name: 'Data Input/Prompt', description: 'Request user input during workflow', icon: FileInput },
  { id: '8', name: 'Script Execution', description: 'Run custom scripts in your workflow', icon: Terminal },
  { id: '9', name: 'Loop/Repeat', description: 'Repeat actions based on criteria', icon: RepeatIcon },
  { id: '10', name: 'Data Transformation', description: 'Manipulate and format data', icon: FileText },
  { id: '11', name: 'Save/Export', description: 'Save data or export results', icon: Save },
  { id: '12', name: 'Integration', description: 'Add actions for third-party apps', icon: Puzzle },
  { id: '13', name: 'Subroutine', description: 'Create reusable mini-workflows', icon: GitFork },
];

interface AddElgatoToolsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectTool: (tool: ElgatoTool) => void;
}

export function AddElgatoToolsDialog({ isOpen, onClose, onSelectTool }: AddElgatoToolsDialogProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Elgato Tools</DialogTitle>
        </DialogHeader>
        <ScrollArea className="mt-8 max-h-[60vh]">
          {elgatoTools.map((tool) => (
            <div key={tool.id} className="flex items-center space-x-4 mb-4">
              <div className="text-primary">
                <tool.icon className="h-6 w-6" />
              </div>
              <div className="flex-grow">
                <h3 className="font-semibold">{tool.name}</h3>
                <p className="text-sm text-muted-foreground">{tool.description}</p>
              </div>
              <Button onClick={() => onSelectTool(tool)}>Add</Button>
            </div>
          ))}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}