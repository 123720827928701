import React, { useState, useEffect } from 'react';
import { fetchPlugins, Plugin, FetchPluginsParams, ApiResponse } from '../services/pluginService';
import { Card, CardContent } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { ScrollArea } from './ui/scroll-area';

interface PluginBrowserProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectAction: (plugin: Plugin, actionName: string) => void;
}

export function PluginBrowser({ onSelectAction, isOpen, onClose }: PluginBrowserProps) {
  const [plugins, setPlugins] = useState<Plugin[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPluginsData = async (pageNum: number) => {
    setLoading(true);
    setError(null);
    try {
      const params: FetchPluginsParams = {
        page: pageNum,
        limit: 20,
        sortBy: 'download_count',
        sortOrder: 'desc',
        search: searchTerm,
      };
      const response: ApiResponse = await fetchPlugins(params);
      setPlugins(response.results);
      if ('total' in response) {
        setTotalPages(Math.ceil((response as ApiResponse & { total: number }).total / params.limit));
      } else {
        setTotalPages(1);
      }
      setPage(pageNum);
    } catch (err) {
      setError('Failed to fetch plugins. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchPluginsData(1);
    }
  }, [isOpen]);

  const handleSearch = () => {
    fetchPluginsData(1);
  };

  const handlePrevious = () => {
    if (page > 1) {
      fetchPluginsData(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      fetchPluginsData(page + 1);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[725px]">
        <DialogHeader>
          <DialogTitle>Add Plugin</DialogTitle>
        </DialogHeader>
        <div className="flex items-center space-x-2 mb-4">
          <Input
            type="text"
            placeholder="Search plugins..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          <Button onClick={handleSearch}>Search</Button>
          <Button variant="outline">Downloads ↓</Button>
          <Button variant="outline">Date Added</Button>
        </div>
        <ScrollArea className="h-[500px]">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {loading ? (
              <p>Loading plugins...</p>
            ) : error ? (
              <p className="text-red-500">{error}</p>
            ) : plugins.length > 0 ? (
              plugins.map((plugin) => (
                <Card key={plugin.id} className="flex flex-col">
                  <CardContent className="flex flex-col p-4">
                    <div className="flex items-center space-x-2 mb-2">
                      {plugin.app_icon && (
                        <img
                          src={plugin.app_icon}
                          alt={`${plugin.name} icon`}
                          className="h-8 w-8 object-contain"
                        />
                      )}
                      <h3 className="font-bold">{plugin.name}</h3>
                    </div>
                    <p className="text-sm text-muted-foreground mb-4 line-clamp-2 h-10 overflow-hidden">
                      {plugin.description}
                    </p>
                    <Button 
                      className="w-full mt-auto" 
                      onClick={() => onSelectAction(plugin, 'default')}
                    >
                      Add Plugin
                    </Button>
                  </CardContent>
                </Card>
              ))
            ) : (
              <p>No plugins found.</p>
            )}
          </div>
        </ScrollArea>
        <div className="mt-4 flex justify-between items-center">
          <Button variant="outline" onClick={handlePrevious} disabled={page === 1}>
            Previous
          </Button>
          <span>Page {page} of {totalPages}</span>
          <Button variant="outline" onClick={handleNext} disabled={page === totalPages}>
            Next
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}