import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Label } from './ui/label';

interface CustomActionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (actionName: string, actionScript: string) => void;
}

export function CustomActionDialog({ isOpen, onClose, onSave }: CustomActionDialogProps) {
  const [actionName, setActionName] = useState('');
  const [actionScript, setActionScript] = useState('');

  const handleSave = () => {
    onSave(actionName, actionScript);
    setActionName('');
    setActionScript('');
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create Custom Action</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Name
            </Label>
            <Input
              id="name"
              value={actionName}
              onChange={(e) => setActionName(e.target.value)}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="script" className="text-right">
              Script
            </Label>
            <textarea
              id="script"
              value={actionScript}
              onChange={(e) => setActionScript(e.target.value)}
              className="col-span-3 h-32 p-2 border rounded"
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Save Custom Action</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}