import React from 'react';
import { Button } from './ui/button';
import { Plus, PlugIcon, SearchIcon, Settings } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

interface AddActionMenuProps {
  onAddPlugin: () => void;
  onBrowseIntegrations: () => void;
  onAddElgatoTools: () => void;
}

export function AddActionMenu({ onAddPlugin, onBrowseIntegrations, onAddElgatoTools }: AddActionMenuProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon" className="rounded-full h-8 w-8">
          <Plus className="h-4 w-4" />
          <span className="sr-only">Add action</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="center">
        <DropdownMenuItem onClick={onAddPlugin}>
          <PlugIcon className="mr-2 h-4 w-4" />
          <span>Add Plugin</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onBrowseIntegrations}>
          <SearchIcon className="mr-2 h-4 w-4" />
          <span>Browse Integrations</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={onAddElgatoTools}>
          <Settings className="mr-2 h-4 w-4" />
          <span>Add Elgato Tools</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}