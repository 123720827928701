import { Category } from '../types/types'
import { FaSpotify, FaDiscord, FaYoutube, FaTwitter, FaTwitch, FaVolumeUp, FaMicrophone, FaCamera, FaHeadphones, FaMusic, FaUsers, FaLightbulb } from 'react-icons/fa'
import { SiObsstudio, SiZendesk, SiElgato } from 'react-icons/si'
import { MdVideoLibrary } from 'react-icons/md'
import { GiMusicalNotes } from 'react-icons/gi'

export const categories: Category[] = [
  {
    name: "Elgato",
    icon: SiElgato,
    subcategories: [
      {
        name: "Stream Deck",
        items: [
          { name: "Change Profile", pluginName: "Stream Deck", logo: "SiElgato" },
          { name: "Switch to Folder", pluginName: "Stream Deck", logo: "SiElgato" },
          { name: "Trigger Hotkey", pluginName: "Stream Deck", logo: "SiElgato" },
        ]
      },
      {
        name: "Camera Hub",
        items: [
          { name: "Adjust Focus", pluginName: "Camera Hub", logo: "SiElgato" },
          { name: "Change Zoom", pluginName: "Camera Hub", logo: "SiElgato" },
          { name: "Toggle Auto Focus", pluginName: "Camera Hub", logo: "SiElgato" },
        ]
      },
      {
        name: "Wave Link",
        items: [
          { name: "Adjust Volume", pluginName: "Wave Link", logo: "SiElgato" },
          { name: "Mute/Unmute", pluginName: "Wave Link", logo: "SiElgato" },
          { name: "Switch Output", pluginName: "Wave Link", logo: "SiElgato" },
        ]
      },
      {
        name: "Capture",
        items: [
          { name: "Start Recording", pluginName: "Capture", logo: "SiElgato" },
          { name: "Stop Recording", pluginName: "Capture", logo: "SiElgato" },
          { name: "Take Screenshot", pluginName: "Capture", logo: "SiElgato" },
        ]
      },
      {
        name: "Key Light",
        items: [
          { name: "Toggle Light", pluginName: "Key Light", logo: "SiElgato" },
          { name: "Adjust Brightness", pluginName: "Key Light", logo: "SiElgato" },
          { name: "Change Color Temperature", pluginName: "Key Light", logo: "SiElgato" },
        ]
      }
    ]
  },
  {
    name: "OBS Studio",
    icon: SiObsstudio,
    items: [
      { name: "Start Stream", pluginName: "OBS Studio", logo: "SiObsstudio" },
      { name: "Stop Stream", pluginName: "OBS Studio", logo: "SiObsstudio" },
      { name: "Switch Scene", pluginName: "OBS Studio", logo: "SiObsstudio" },
      { name: "Toggle Mute", pluginName: "OBS Studio", logo: "SiObsstudio" },
    ]
  },
  {
    name: "Spotify",
    icon: FaSpotify,
    items: [
      { name: "Play/Pause", pluginName: "Spotify", logo: "FaSpotify" },
      { name: "Next Track", pluginName: "Spotify", logo: "FaSpotify" },
      { name: "Previous Track", pluginName: "Spotify", logo: "FaSpotify" },
    ]
  },
  {
    name: "Discord",
    icon: FaDiscord,
    items: [
      { name: "Mute/Unmute", pluginName: "Discord", logo: "FaDiscord" },
      { name: "Deafen/Undeafen", pluginName: "Discord", logo: "FaDiscord" },
      { name: "Switch Channel", pluginName: "Discord", logo: "FaDiscord" },
    ]
  },
  {
    name: "YouTube",
    icon: FaYoutube,
    items: [
      { name: "Start Stream", pluginName: "YouTube", logo: "FaYoutube" },
      { name: "Stop Stream", pluginName: "YouTube", logo: "FaYoutube" },
      { name: "Upload Video", pluginName: "YouTube", logo: "FaYoutube" },
    ]
  },
  {
    name: "Twitch",
    icon: FaTwitch,
    items: [
      { name: "Start Stream", pluginName: "Twitch", logo: "FaTwitch" },
      { name: "Stop Stream", pluginName: "Twitch", logo: "FaTwitch" },
      { name: "Create Clip", pluginName: "Twitch", logo: "FaTwitch" },
    ]
  },
  {
    name: "Twitter",
    icon: FaTwitter,
    items: [
      { name: "Post Tweet", pluginName: "Twitter", logo: "FaTwitter" },
      { name: "Retweet", pluginName: "Twitter", logo: "FaTwitter" },
      { name: "Like Tweet", pluginName: "Twitter", logo: "FaTwitter" },
    ]
  },
]